const cleanUrl = (url: string): string => {
  // Remove "https://" if present
  let convertedUrl = url.replace("https://", "");

  // Remove "www." if present
  convertedUrl = convertedUrl.replace("www.", "");

  // Remove trailing slashes
  convertedUrl = convertedUrl.replace(/\/+$/, "");

  return convertedUrl;
};

export default cleanUrl;
