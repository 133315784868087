import React from "react";
import { ITemplate } from "../../interfaces";
// import "../../styles/components/templates/Archive-RSTemplate.scss";
import { cleanUrl } from "../../utils";
// import { useStateTemplate } from "../../hooks/index";
// import { RS } from "../../config";
import { baseUrl } from "../../config";

const { website, address, phone, logo, addressLink } = {
  website: "https://www.rorstudios.com.au/",
  address: "U 1, 153-155 Rooks Rd, Vermont 3133",
  addressLink:
    "https://www.google.com.au/maps/place/Unit+1%2F153-155+Rooks+Rd,+Vermont+VIC+3133/@-37.8326213,145.1850969,17z/data=!3m1!4b1!4m6!3m5!1s0x6ad63ed3539987fb:0x9a62a927992f20b!8m2!3d-37.8326213!4d145.1876718!16s%2Fg%2F11q8vv6n5t",
  phone: ["1300 933 933 ", " +61 3 9000 8933"],
  logo: `${baseUrl}/companyLogos/rs.png`,
};

const RSTemplate = ({ name, job, mobile, email }: ITemplate) => {
  // const { website, address, phone, socials, badgesTop, badgesBottom, logo } =
  //   useStateTemplate(RS);

  return (
    <table>
      <thead>
        <tr>
          <td>
            <img className="RS-logo" src={logo} alt="RS logo" width="300" />
          </td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td
            className="RS-user"
            style={{
              padding: "24px 0 20px",
              borderBottom: "0.5px solid #897f6f",
            }}
          >
            <div
              className="RS-name"
              style={{
                color: "#231f20",
                fontWeight: 600,
                fontSize: "18px",
                textTransform: "uppercase",
                paddingBottom: "4px",
              }}
            >
              {name}
            </div>
            <div
              className="RS-job"
              style={{
                color: "#4e4d50",
                fontWeight: 400,
                fontSize: "16px",
                textTransform: "capitalize",
              }}
            >
              {job}
            </div>
          </td>
        </tr>
        {mobile.length > 0 && (
          <tr>
            <td className="RS-info mobile" style={{ padding: "24px 0 0 0" }}>
              <span
                className="bi-key"
                style={{
                  color: "#4e4d50",
                  fontWeight: 700,
                  fontSize: "12px",
                  textTransform: "uppercase",
                  width: "60px",
                }}
              >
                Mobile
              </span>
              <span
                className="bi-divider"
                style={{
                  color: "#4e4d50",
                  fontWeight: 300,
                  fontSize: "13px",
                  padding: "0 5px",
                }}
              >
                |
              </span>
              <a
                className="bi-value"
                style={{
                  color: "#4e4d50",
                  fontWeight: 400,
                  fontSize: "12px",
                  textDecoration: "none",
                }}
                href={`tel:[${mobile.replace(/\s/g, "")}]`}
              >
                {mobile}
              </a>
            </td>
          </tr>
        )}
        <tr>
          <td
            className={`RS-info ${mobile.length === 0 ? "mobile" : ""}`}
            style={{ padding: mobile.length === 0 ? "24px 0 0 0" : "" }}
          >
            <span
              className="bi-key"
              style={{
                color: "#4e4d50",
                fontWeight: 700,
                fontSize: "12px",
                textTransform: "uppercase",
                width: "60px",
              }}
            >
              Phone
            </span>
            <span
              className="bi-divider"
              style={{
                color: "#4e4d50",
                fontWeight: 300,
                fontSize: "13px",
                padding: "0 5px",
              }}
            >
              |
            </span>
            {phone.map((data, index) => (
              <>
                <a
                  className="bi-value"
                  style={{
                    color: "#4e4d50",
                    fontWeight: 400,
                    fontSize: "12px",
                    textDecoration: "none",
                  }}
                  href={`tel:[${data.replace(/\s/g, "")}]`}
                >
                  {data}
                </a>
                {index % 2 === 0 && (
                  <span
                    className="bi-key"
                    style={{
                      color: "#4e4d50",
                      fontWeight: 700,
                      fontSize: "12px",
                      textTransform: "uppercase",
                      width: "60px",
                    }}
                  >
                    or
                  </span>
                )}
              </>
            ))}
          </td>
        </tr>
        <tr>
          <td className="RS-info">
            <span
              className="bi-key"
              style={{
                color: "#4e4d50",
                fontWeight: 700,
                fontSize: "12px",
                textTransform: "uppercase",
                width: "60px",
              }}
            >
              Email
            </span>
            <span
              className="bi-divider"
              style={{
                color: "#4e4d50",
                fontWeight: 300,
                fontSize: "13px",
                padding: "0 5px",
              }}
            >
              |
            </span>
            <a
              className="bi-value"
              style={{
                color: "#4e4d50",
                fontWeight: 400,
                fontSize: "12px",
                textDecoration: "none",
              }}
              href={`mailto:${email}`}
              target="_blank"
              rel="noreferrer"
            >
              {email}
            </a>
          </td>
        </tr>
        <tr>
          <td className="RS-info">
            <span
              className="bi-key"
              style={{
                color: "#4e4d50",
                fontWeight: 700,
                fontSize: "12px",
                textTransform: "uppercase",
                width: "60px",
              }}
            >
              Website
            </span>
            <span
              className="bi-divider"
              style={{
                color: "#4e4d50",
                fontWeight: 300,
                fontSize: "13px",
                padding: "0 5px",
              }}
            >
              |
            </span>
            <a
              className="bi-value"
              style={{
                color: "#4e4d50",
                fontWeight: 400,
                fontSize: "12px",
                textDecoration: "none",
              }}
              href={website}
              target="_blank"
              rel="noreferrer"
            >
              {cleanUrl(website)}
            </a>
          </td>
        </tr>
        <tr>
          <td className="RS-info">
            <span
              className="bi-key"
              style={{
                color: "#4e4d50",
                fontWeight: 700,
                fontSize: "12px",
                textTransform: "uppercase",
                width: "60px",
              }}
            >
              Address
            </span>
            <span
              className="bi-divider"
              style={{
                color: "#4e4d50",
                fontWeight: 300,
                fontSize: "13px",
                padding: "0 5px",
              }}
            >
              |
            </span>
            <a
              className="bi-value"
              style={{
                color: "#4e4d50",
                fontWeight: 400,
                fontSize: "12px",
                textDecoration: "none",
              }}
              href={addressLink}
              target="_blank"
              rel="noreferrer"
            >
              {address}
            </a>
          </td>
        </tr>
        {/* 
        <tr>
          <table className="RS-sm">
            {(socials || []).map(({ url, linkLogo }: Social) => {
              return (
                <td className="RS-sm-link-container" key={linkLogo[0].url}>
                  <a
                    className="RS-sm-link"
                    href={url}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      className="RS-sm-link-img"
                      src={linkLogo[0].url}
                      alt="social media"
                      height="29"
                    />
                  </a>
                </td>
              );
            })}
          </table>
        </tr>
        <tr>
          <table className="RS-badges">
            {(badgesTop || []).map((obj: Certifications) => {
              return (
                <td className="RS-badges-link-img-container" key={obj.id}>
                  <img
                    className="RS-badges-link-img"
                    src=""
                    alt="badges"
                    height="60"
                  />
                </td>
              );
            })}
          </table>
        </tr>
        <tr>
          <table className="RS-badges">
            {(badgesBottom || []).map((obj: Certifications) => {
              return (
                <td className="RS-badges-link-img-container" key={obj.id}>
                  <img
                    className="RS-badges-link-img"
                    src=""
                    alt="badges"
                    height="60"
                  />
                </td>
              );
            })}
          </table>
        </tr> */}
      </tbody>
    </table>
  );
};

export default RSTemplate;
