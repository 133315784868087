import React from "react";
import "../styles/components/ControlPanel.scss";
import useStore from "../stores/store";

interface IControlPanel {
  nameInput: JSX.Element;
  jobInput: JSX.Element;
  mobileInput: JSX.Element;
  emailInput: JSX.Element;
  radioTemplate: JSX.Element;
}

const ControlPanel = ({
  nameInput,
  jobInput,
  mobileInput,
  emailInput,
  radioTemplate,
}: IControlPanel) => {
  const { currentPageIndex } = useStore();
  return (
    <div className="control-panel">
      <form className="cp-submission-form">
        {nameInput}
        {currentPageIndex ? jobInput : null}
        {currentPageIndex ? mobileInput : null}
        {currentPageIndex ? emailInput : null}
        {radioTemplate}
      </form>
    </div>
  );
};

export default ControlPanel;
