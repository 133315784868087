import * as htmlToImage from "html-to-image";
import { saveAs } from "file-saver";

const getImage = (node: any, fileName: string | undefined) => {
  htmlToImage.toPng(node).then(function (raw) {
    saveAs(raw, fileName);
  });
};

export default getImage;
