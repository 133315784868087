import React from "react";
import { ITemplate } from "../../interfaces";
// import "../../styles/components/templates/Archive-BAVTemplate.scss";
import { Certifications, Social } from "../../types";
import { cleanUrl } from "../../utils";
import { baseUrl } from "../../config";

const { website, address, socials, phone, badgesTop, badgesBottom, logo } = {
  website: "https://www.boutiqueav.com.au/",
  address: "U 1, 153-155 Rooks Rd, Vermont 3133",
  socials: [
    {
      url: "https://twitter.com/BoutiqueAV",
      linkLogo: [{ url: `${baseUrl}/socialLogos/bav/twitter.png`, id: 1 }],
    },
    {
      url: "https://www.facebook.com/boutiqueav/",
      linkLogo: [{ url: `${baseUrl}/socialLogos/bav/facebook.png`, id: 2 }],
    },
    {
      url: "https://au.linkedin.com/company/boutique-av",
      linkLogo: [{ url: `${baseUrl}/socialLogos/bav/linkedin.png`, id: 3 }],
    },
    {
      url: "https://www.instagram.com/boutique.av/",
      linkLogo: [{ url: `${baseUrl}/socialLogos/bav/instagram.png`, id: 4 }],
    },
    {
      url: "https://www.youtube.com/channel/UC4-GzStdpBTyoncEToVxcXA",
      linkLogo: [{ url: `${baseUrl}/socialLogos/bav/youtube.png`, id: 5 }],
    },
    {
      url: "https://www.google.com.au/maps/place/Unit+1%2F153-155+Rooks+Rd,+Vermont+VIC+3133/@-37.8326213,145.1850969,17z/data=!3m1!4b1!4m6!3m5!1s0x6ad63ed3539987fb:0x9a62a927992f20b!8m2!3d-37.8326213!4d145.1876718!16s%2Fg%2F11q8vv6n5t",
      linkLogo: [{ url: `${baseUrl}/socialLogos/bav/location.png`, id: 6 }],
    },
  ],
  phone: ["1300 933 933 ", " +61 3 9000 8933"],
  badgesTop: [
    { url: `${baseUrl}/certifications/eco.png`, id: 1 },
    { url: `${baseUrl}/certifications/neca.png`, id: 1 },
    { url: `${baseUrl}/certifications/thx.png`, id: 1 },
  ],
  badgesBottom: [
    { url: `${baseUrl}/certifications/uewa.png`, id: 1 },
    { url: `${baseUrl}/certifications/cedia.png`, id: 1 },
  ],
  logo: `${baseUrl}/companyLogos/bav.png`,
};

const BAVTemplate = ({ name, job, mobile, email }: ITemplate) => {
  // DISABLED API FETCHING, DATA FROM FRONTEND
  // const { website, address, phone, socials, badgesTop, badgesBottom, logo } =
  //   useStateTemplate(BAV);

  return (
    <table>
      <thead style={{ borderBottom: "1px solid #000" }}>
        <tr>
          <td>
            <img className="bav-logo" src={logo} alt="bav logo" width="300" />
          </td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td
            className="bav-user"
            style={{
              padding: "24px 0 20px",
              borderBottom: "0.5px solid #897f6f",
            }}
          >
            <div
              className="bav-name"
              style={{
                color: "#897f6f",
                fontWeight: 600,
                fontSize: "18px",
                textTransform: "uppercase",
                display: "block",
                paddingBottom: "4px",
              }}
            >
              {name}
            </div>
            <div
              className="bav-job"
              style={{
                display: "block",
                color: "#897f6f",
                fontWeight: 400,
                fontSize: "16px",
                textTransform: "capitalize",
              }}
            >
              {job}
            </div>
          </td>
        </tr>
        {mobile.length > 0 && (
          <tr>
            <td className="bav-info mobile" style={{ padding: "24px 0 0 0" }}>
              <span
                className="bi-key"
                style={{
                  color: "#d7b988",
                  fontWeight: 700,
                  fontSize: "12px",
                  textTransform: "uppercase",
                  width: "60px",
                  padding: "24x 0 0 0",
                }}
              >
                Mobile
              </span>
              <span
                className="bi-divider"
                style={{
                  color: "#d7b988",
                  fontWeight: 300,
                  fontSize: "13px",
                  padding: "0 5px",
                }}
              >
                |
              </span>
              <a
                style={{
                  color: "#897f6f",
                  fontWeight: 400,
                  fontSize: "12px",
                  textDecoration: "none",
                }}
                className="bi-value"
                href={`tel:[${mobile.replace(/\s/g, "")}]`}
              >
                {mobile}
              </a>
            </td>
          </tr>
        )}
        <tr>
          <td
            className={`bav-info ${mobile.length === 0 ? "mobile" : ""}`}
            style={{ padding: mobile.length === 0 ? "24px 0 0 0" : "" }}
          >
            <span
              className="bi-key"
              style={{
                color: "#d7b988",
                fontWeight: 700,
                fontSize: "12px",
                textTransform: "uppercase",
                width: "60px",
              }}
            >
              Phone
            </span>
            <span
              className="bi-divider"
              style={{
                color: "#d7b988",
                fontWeight: 300,
                fontSize: "13px",
                padding: "0 5px",
              }}
            >
              |
            </span>
            {phone.map((data, index) => (
              <>
                <a
                  className="bi-value"
                  style={{
                    color: "#897f6f",
                    fontWeight: 400,
                    fontSize: "12px",
                    textDecoration: "none",
                  }}
                  href={`tel:[${data.replace(/\s/g, "")}]`}
                >
                  {data}
                </a>
                {index % 2 === 0 && (
                  <span
                    className="bi-key"
                    style={{
                      color: "#d7b988",
                      fontWeight: 700,
                      fontSize: "12px",
                      textTransform: "uppercase",
                      width: "60px",
                    }}
                  >
                    or
                  </span>
                )}
              </>
            ))}
          </td>
        </tr>
        <tr>
          <td className="bav-info">
            <span
              className="bi-key"
              style={{
                color: "#d7b988",
                fontWeight: 700,
                fontSize: "12px",
                textTransform: "uppercase",
                width: "60px",
              }}
            >
              Email
            </span>
            <span
              className="bi-divider"
              style={{
                color: "#d7b988",
                fontWeight: 300,
                fontSize: "13px",
                padding: "0 5px",
              }}
            >
              |
            </span>
            <a
              className="bi-value"
              style={{
                color: "#897f6f",
                fontWeight: 400,
                fontSize: "12px",
                textDecoration: "none",
              }}
              href={`mailto:${email}`}
              target="_blank"
              rel="noreferrer"
            >
              {email}
            </a>
          </td>
        </tr>
        <tr>
          <td className="bav-info">
            <span
              className="bi-key"
              style={{
                color: "#d7b988",
                fontWeight: 700,
                fontSize: "12px",
                textTransform: "uppercase",
                width: "60px",
              }}
            >
              Website
            </span>
            <span
              className="bi-divider"
              style={{
                color: "#d7b988",
                fontWeight: 300,
                fontSize: "13px",
                padding: "0 5px",
              }}
            >
              |
            </span>
            <a
              className="bi-value"
              style={{
                color: "#897f6f",
                fontWeight: 400,
                fontSize: "12px",
                textDecoration: "none",
              }}
              href={website}
              target="_blank"
              rel="noreferrer"
            >
              {cleanUrl(website)}
            </a>
          </td>
        </tr>
        <tr>
          <td className="bav-info">
            <span
              className="bi-key"
              style={{
                color: "#d7b988",
                fontWeight: 700,
                fontSize: "12px",
                textTransform: "uppercase",
                width: "60px",
              }}
            >
              Address
            </span>
            <span
              className="bi-divider"
              style={{
                color: "#d7b988",
                fontWeight: 300,
                fontSize: "13px",
                padding: "0 5px",
              }}
            >
              |
            </span>
            <a
              className="bi-value"
              style={{
                color: "#897f6f",
                fontWeight: 400,
                fontSize: "12px",
                textDecoration: "none",
              }}
              href={socials != null ? socials[5].url : ""}
              target="_blank"
              rel="noreferrer"
            >
              {address}
            </a>
          </td>
        </tr>

        <tr>
          <table
            className="bav-sm"
            style={{
              padding: "25px 0",
            }}
          >
            {(socials || []).map(({ url, linkLogo }: Social) => {
              return (
                <td
                  className="bav-sm-link-container"
                  style={{
                    margin: "0.1px",
                    paddingRight: "25.6px",
                  }}
                  key={linkLogo[0].url}
                >
                  <a
                    className="bav-sm-link"
                    href={url}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      className="bav-sm-link-img"
                      src={linkLogo[0].url}
                      alt="social media"
                      height="29"
                    />
                  </a>
                </td>
              );
            })}
          </table>
        </tr>
        <tr>
          <table className="bav-badges">
            {(badgesTop || []).map((obj: Certifications) => {
              return (
                <td
                  className="bav-badges-link-img-container"
                  style={{
                    margin: "0.1px",
                    padding: "0 25.6px 0 0",
                  }}
                  key={obj.url}
                >
                  <img
                    className="bav-badges-link-img"
                    src={obj.url}
                    alt="badges"
                    height="60"
                  />
                </td>
              );
            })}
          </table>
        </tr>
        <tr>
          <table className="bav-badges">
            {(badgesBottom || []).map((obj: Certifications) => {
              return (
                <td
                  className="bav-badges-link-img-container"
                  style={{
                    margin: "0.1px",
                    padding: "0 25.6px 0 0",
                  }}
                  key={obj.url}
                >
                  <img
                    className="bav-badges-link-img"
                    src={obj.url}
                    alt="badges"
                    height="60"
                  />
                </td>
              );
            })}
          </table>
        </tr>
      </tbody>
    </table>
  );
};

export default BAVTemplate;
