import React from "react";
import { ITemplate } from "../../interfaces";
import { cleanUrl } from "../../utils";
import { baseUrl } from "../../config";
// import "../../styles/components/templates/BZTTemplate.scss";

const { website, websiteLink, address, addressLink, phone } = {
  website: "beyondzero.tech",
  websiteLink: "https://www.beyondzero.tech/",
  address: "Unit 1, 153-155 Rooks Rd, Vermont VIC 3133",
  addressLink:
    "https://www.google.com.au/maps/place/Unit+1%2F153-155+Rooks+Rd,+Vermont+VIC+3133/@-37.8326213,145.1850969,17z/data=!3m1!4b1!4m6!3m5!1s0x6ad63ed3539987fb:0x9a62a927992f20b!8m2!3d-37.8326213!4d145.1876718!16s%2Fg%2F11q8vv6n5t",
  phone: ["1300 933 933 ", " +61 3 9000 8933"],
};

const BZTTemplate = ({ name, job, mobile, email, option }: ITemplate) => {
  // const { website, address, phone, socials, badgesTop, badgesBottom, logo } =
  //   useStateTemplate(BZT);

  const logo = `${baseUrl}/companyLogos/bzt.${
    option?.toLowerCase().includes("gif") ? "gif" : "png"
  }`;
  return (
    <table>
      <tbody>
        <tr>
          <td
            className="BZT-user"
            style={{
              padding: "24px 0 12px",
            }}
          >
            <div
              className="BZT-name"
              style={{
                textTransform: "capitalize",
                fontWeight: 700,
                fontSize: "13px",
                paddingBottom: "4px",
                fontFamily: "Arial, sans-serif",
              }}
            >
              {name}
            </div>
            <div
              className="BZT-job"
              style={{
                fontWeight: 400,
                fontSize: "13px",
                textTransform: "capitalize",
              }}
            >
              {job}
            </div>
          </td>
        </tr>
        <table>
          <tbody>
            <tr>
              <td className="BZT-sig-container">
                {/* inline style custer for hubspot email signature .BZT-sig-cluster */}
                <table
                  style={{
                    borderTop: "1px solid rgb(0, 78, 66)",
                    borderBottom: "1px solid rgb(0, 78, 66)",
                    paddingTop: "20px",
                    paddingBottom: "20px",
                    borderLeft: "none",
                    borderRight: "none",
                  }}
                >
                  <tbody>
                    <tr>
                      <td
                        className="BZT-logo-container"
                        style={{
                          paddingRight: "21px",
                          paddingTop: "10px",
                          boxSizing: "content-box",
                        }}
                      >
                        <img
                          src={logo}
                          alt="BeyondZero Technologies logo"
                          width={180}
                        />
                      </td>
                      <td>
                        <table
                          className="links-container"
                          cellPadding={0}
                          cellSpacing={0}
                          style={{ borderCollapse: "collapse" }}
                        >
                          <tbody>
                            <tr className="BZT-info email">
                              <td
                                className="icon-container"
                                style={{ padding: "3px 5px 0px 0px" }}
                              >
                                <img
                                  src="https://ci5.googleusercontent.com/proxy/QnD4MpC8FNUZ6sNonliaWjm_c5MhCZnWODtgVtj7dtoBZOABmbJihWRvs3e8zXS_S1AzFrXeMVnBumZl_8lKPDexwA7j5yasREyDR_-XUVMn=s0-d-e1-ft#https://signaturehound.com/api/v1/png/email/default/004e42.png"
                                  alt=""
                                  width="18"
                                  height="18"
                                />
                              </td>
                              <td>
                                <a
                                  className="bi-value"
                                  href={`mailto:${email}`}
                                  target="_blank"
                                  rel="noreferrer"
                                  style={{
                                    color: "#000000",
                                    fontSize: "13px",
                                    textDecoration: "none",
                                    verticalAlign: "middle",
                                    lineHeight: "24px",
                                    fontFamily: "Arial, sans-serif",
                                    fontWeight: 500,
                                    padding: "0 0 0 1px",
                                  }}
                                >
                                  {email}
                                </a>
                              </td>
                            </tr>
                            {mobile.length > 0 && (
                              <tr className="BZT-info">
                                <td
                                  className="icon-container"
                                  style={{ padding: "3px 5px 0px 0px" }}
                                >
                                  <img
                                    src="https://ci5.googleusercontent.com/proxy/ty10ztfdA7Z4eLuvcongagIfRme-z-TIK6NmBF0TeNp6QkwVU6S7q24YPNgDnBTgr5aP1nSCESrqMMUw1_C8O2zUPL_8-i0BMpea0qk11FZ-_w=s0-d-e1-ft#https://signaturehound.com/api/v1/png/mobile/default/004e42.png"
                                    alt=""
                                    width="18"
                                    height="18"
                                  />
                                </td>
                                <td>
                                  <a
                                    className="bi-value"
                                    href={`tel:[${mobile.replace(/\s/g, "")}]`}
                                    style={{
                                      color: "#000000",
                                      fontSize: "13px",
                                      textDecoration: "none",
                                      verticalAlign: "middle",
                                      lineHeight: "24px",
                                      fontFamily: "Arial, sans-serif",
                                      fontWeight: 500,
                                    }}
                                  >
                                    {mobile}
                                  </a>
                                </td>
                              </tr>
                            )}

                            <tr className="BZT-info">
                              <td className="icon-container">
                                <img
                                  src="https://ci3.googleusercontent.com/proxy/jHxn85AZG1rl3BElpdLZkdQ2lsDdtbw_ftS-p2EKTaNm_h-Vt5-LRiq7KWJp1ivA-uWJvIQ_KbDw2leOqs2GhgyK4Z3y7kKt25Kg8jkwzKEJ=s0-d-e1-ft#https://signaturehound.com/api/v1/png/phone/default/004e42.png"
                                  alt=""
                                  width="18"
                                  height="18"
                                />
                              </td>
                              <td>
                                {phone.map((data, index) => (
                                  <>
                                    <a
                                      className="bi-value"
                                      href={`tel:[${data.replace(/\s/g, "")}]`}
                                      style={{
                                        color: "#000000",
                                        fontSize: "13px",
                                        textDecoration: "none",
                                        verticalAlign: "middle",
                                        lineHeight: "24px",
                                        fontFamily: "Arial, sans-serif",
                                        fontWeight: 500,
                                      }}
                                    >
                                      {data}
                                    </a>
                                    {index % 2 === 0 && (
                                      <span
                                        style={{
                                          color: "#074e42",
                                          fontWeight: 700,
                                          fontSize: "13px",
                                          textTransform: "uppercase",
                                          width: "60px",
                                        }}
                                      >
                                        or
                                      </span>
                                    )}
                                  </>
                                ))}
                              </td>
                            </tr>
                            <tr className="BZT-info">
                              <td className="icon-container">
                                <img
                                  src="https://ci3.googleusercontent.com/proxy/VXrSeXMNK0gEnwc8b-ZHoIgOuXC_LP5ZW4OTN04pSPkIxqaXDNhe4Sw0W7aZD6C15BFQ2C-Qn_R-Vkz6peGvPME4eh1l9pF_AtqUq4q3ig=s0-d-e1-ft#https://signaturehound.com/api/v1/png/map/default/004e42.png"
                                  alt=""
                                  width="18"
                                  height="18"
                                />
                              </td>
                              <td>
                                <a
                                  className="bi-value"
                                  href={addressLink}
                                  target="_blank"
                                  rel="noreferrer"
                                  style={{
                                    color: "#000000",
                                    fontSize: "13px",
                                    textDecoration: "none",
                                    verticalAlign: "middle",
                                    lineHeight: "24px",
                                    fontFamily: "Arial, sans-serif",
                                    fontWeight: 500,
                                  }}
                                >
                                  {address}
                                </a>
                              </td>
                            </tr>
                            <tr className="BZT-info">
                              <td className="icon-container">
                                <img
                                  src="https://ci3.googleusercontent.com/proxy/GKnjUx7QXR9x-v_pU_N6TOSU1oI7HE7x6k5H1Xr776KLCJ27h6zOEGNnjw-YAMhdcbnpBh0CAeDEc4Gokpai9nY6KxB6gnAQ1seUyv_OlRQ0xpQ=s0-d-e1-ft#https://signaturehound.com/api/v1/png/website/default/004e42.png"
                                  alt=""
                                  width="18"
                                  height="18"
                                  className="icon"
                                />
                              </td>
                              <td>
                                <a
                                  className="bi-link"
                                  href={websiteLink}
                                  target="_blank"
                                  rel="noreferrer"
                                  style={{
                                    fontWeight: 700,
                                    textDecoration: "none",
                                    color: "#074e42",
                                    fontSize: "13px",
                                  }}
                                >
                                  {cleanUrl(website)}
                                </a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </tbody>
    </table>
  );
};

export default BZTTemplate;
