// const apiLink = "https://strapi-rorsignature.designedanddeveloped.com.au";
// const apiTemplateLink =
//   "https://strapi-rorsignature.designedanddeveloped.com.au/Signature-Templates";

// export { apiLink, apiTemplateLink };

// // For post
// export const JWT_TOKEN =
//   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiaWF0IjoxNjQzMDAwMzgxLCJleHAiOjE2NDU1OTIzODF9.OG9KhYNdxw-6soikNMEpdEoMtEpRRszs8aUJs3FQ2tc";

// export const FETCH_HEADERS = {
//   "Content-Type": "application/json",
//   Authorization: `Bearer ${JWT_TOKEN}`,
// };

// export const SUCCESS_STATUS = 200;
export const baseUrl = process.env.REACT_APP_BASE_URL; // this is the base url for the api (example image signature https://ror-signatures.d7d.link//companyLogos/bav.png)
export const BAV = "BAV";
export const ROR = "ROR";
export const RS = "RS";
export const DND = "DND";
export const BZT_GIF = "BZT Gif";
export const BZT_PNG = "BZT Png";
export const options = [BAV, ROR, RS, DND, BZT_GIF, BZT_PNG];
