import BAVTemplate from "./BAVTemplate";
import RORTemplate from "./RORTemplate";
import RSTemplate from "./RSTemplate";
import DNDTemplate from "./DNDTemplate";
import BZTTemplate from "./BZTTemplate";

import { BAV, ROR, RS, DND, BZT_GIF, BZT_PNG } from "../../config";

export default [
  {
    id: BAV,
    template: BAVTemplate,
  },
  {
    id: ROR,
    template: RORTemplate,
  },
  {
    id: RS,
    template: RSTemplate,
  },
  {
    id: DND,
    template: DNDTemplate,
  },
  {
    id: BZT_GIF,
    template: BZTTemplate,
  },
  {
    id: BZT_PNG,
    template: BZTTemplate,
  },
];
