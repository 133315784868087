import React, { useEffect, useState } from "react";
import "../styles/components/DesignPanel.scss";
import ClipboardJS from "clipboard";

interface IDesignPanel {
  template: JSX.Element | undefined;
}

const DesignPanel = ({ template }: IDesignPanel) => {
  const [responseText, setResponseText] = useState("");

  const onResponse = (text: string) => {
    setResponseText(text);

    setTimeout(() => {
      setResponseText("");
    }, 5000);
  };

  const templateId = "templateid";
  const copyButton = "copybutton";
  const codeButton = "codebutton";

  useEffect(() => {
    const clipboard = new ClipboardJS(
      document.getElementById(codeButton) as HTMLElement,
      {
        text() {
          return document.getElementById(templateId)!.innerHTML;
        },
      }
    );

    clipboard.on("success", (e) => {
      onResponse("Code copied! 🙂");
      e.clearSelection();
    });

    clipboard.on("error", () => {
      onResponse("Sorry bro, didn't work! 😔");
    });
  }, [codeButton]);

  useEffect(() => {
    const clipboard = new ClipboardJS(
      document.getElementById(copyButton) as HTMLElement
    );

    clipboard.on("success", (e) => {
      onResponse("Signature copied! 🙂");
      e.clearSelection();
    });

    clipboard.on("error", () => {
      onResponse("Sorry bro, didn't work! 😔");
    });
  }, [copyButton]);

  const [signatureLen, setSignatureLen] = useState(0);

  useEffect(() => {
    const templateHTML = document.getElementById(templateId);
    if (templateHTML !== null) {
      setSignatureLen(templateHTML.innerHTML.toString().length);
    }
  }, [template]);

  return (
    <div className="design-panel">
      <div className="dp-inner">
        <div className="dp-inner-header">{`SIGNATURE PREVIEW - ${signatureLen}`}</div>
        <div className="dp-inner-body" id={templateId}>
          {template}
        </div>
        <div className="dp-inner-footer">
          <div className="dp-inner-footer-top">
            <button
              type="button"
              id={copyButton}
              data-clipboard-action="copy"
              data-clipboard-target={`#${templateId}`}
              className="dp-button"
            >
              Copy
            </button>

            <button
              type="button"
              id={codeButton}
              data-clipboard-action="copy"
              className="dp-button"
            >
              Code
            </button>
          </div>
          <div className="dp-innter-footer-bottom">
            <span className="dp-status">{responseText}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DesignPanel;
