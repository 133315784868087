import React, { useState } from "react";
import { Input } from "../components";

interface IUser {
  type: string;
  name: string;
}

export default function useInput({ type, name }: IUser) {
  const [value, setValue] = useState("");

  const input = (
    <Input type={type} name={name} value={value} setValue={setValue} />
  );

  return { value, input };
}
