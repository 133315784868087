import React, { useEffect, useState } from "react";
import "../styles/components/AvatarPanel.scss";
import BAV from "../assets/logos/BAV.png";
import DND from "../assets/logos/DND.png";
import BZT from "../assets/logos/BZT.png";
import ROR from "../assets/logos/ROR.png";
import RS from "../assets/logos/RS.png";
import BT from "../assets/logos/BT.png";
import getImage from "../utils/getImage";

interface IAvatarPanel {
  company: string;
  name: string;
}

const AvatarPanel = ({ company, name }: IAvatarPanel) => {
  const [imgSrc, setimgSrc] = useState<any>(BAV);
  const [initials, setInitials] = useState("");
  const [textColour, setTextColour] = useState("ffffff");
  const [textFontFamily, setFontFamily] = useState("NexaBold");
  const [offset, setOffset] = useState("55%");

  const handleDownload = () => {
    const data = document.getElementById("avatar") as HTMLElement;
    const fileName = `${[initials, company, Date.now().toString()].join(
      "-"
    )}.png`;
    getImage(data, fileName);
  };

  useEffect(() => {
    switch (company) {
      case "BAV":
        setimgSrc(BAV);
        setTextColour("#D7B988");
        setFontFamily("NunitoSans");
        setOffset("50%");
        break;
      case "DND":
        setimgSrc(DND);
        setTextColour("#98D0BC");
        setFontFamily("Poppins");
        setOffset("50%");
        break;
      case "BZT Gif":
      case "BZT Png":
        setimgSrc(BZT);
        setTextColour("#FFFFFF");
        setFontFamily("NexaBold");
        setOffset("55%");
        break;
      case "ROR":
        setimgSrc(ROR);
        setTextColour("#FFFFFF");
        setFontFamily("Inter");
        setOffset("50%");
        break;
      case "RS":
        setimgSrc(RS);
        setTextColour("#FFFFFF");
        setFontFamily("Inter");
        setOffset("50%");
        break;
      case "BT":
        setimgSrc(BT);
        setTextColour("#FFFFFF");
        setFontFamily("NunitoSans");
        setOffset("50%");
        break;
      default:
        setimgSrc(null);
        setTextColour("#FFFFFF");
        setOffset("50%");
        break;
    }
  }, [company]);

  useEffect(() => {
    setInitials(
      name
        .split(" ")
        .map((s) => (s[0] ?? "").toUpperCase())
        .join("")
        .slice(0, 3)
    );
  }, [name]);

  return (
    <div className="avatar-panel">
      <div className="ap-inner">
        <div className="ap-inner-header">AVATAR PREVIEW</div>
        <div className="ap-inner-body">
          <div className="avatar" id="avatar">
            <img
              className="logo"
              src={imgSrc}
              alt="Company Logo with your initials"
            />
            <div
              className="initials"
              style={{
                color: textColour,
                fontFamily: textFontFamily,
                top: offset,
              }}
            >
              {initials}
            </div>
          </div>
        </div>
        <div className="ap-inner-footer">
          <div className="ap-inner-footer-top">
            <button
              type="button"
              onClick={handleDownload}
              className="ap-button"
            >
              Download
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AvatarPanel;
