import { create } from "zustand";

interface storeInput {
  currentPageIndex: number;
  setCurrentPageIndex: (newIndex: number) => void;
}

const useStore = create<storeInput>((set) => ({
  currentPageIndex: 1,
  setCurrentPageIndex: (currentPageIndex: number) => {
    set((state) => ({ ...state, currentPageIndex }));
  },
}));

export default useStore;
