import React from "react";
import "../styles/components/RadioButtons.scss";

interface IRadioButton {
  name: string;
  options: string[];
  setCurrentValue: React.Dispatch<React.SetStateAction<string>>;
  currentValue: string;
}

const RadioButtons = ({
  name,
  options,
  setCurrentValue,
  currentValue,
}: IRadioButton) => {
  const handleOptionChange = (val: string) => {
    setCurrentValue(val);
  };

  return (
    <div className="radio-buttons">
      <div className="rb-title">Templates</div>
      <div className="rb-container" id="rb-container">
        {options.map((val) => {
          return (
            <p key={`p${val}`}>
              <input
                type="radio"
                id={val}
                name={name}
                value={val}
                onChange={(e) => {
                  handleOptionChange(e.target.value);
                }}
                checked={val === currentValue}
              />
              <label className="rb-label" htmlFor={val}>
                {val}
              </label>
            </p>
          );
        })}
      </div>
    </div>
  );
};

export default RadioButtons;
