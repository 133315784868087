import React from "react";
import { ITemplate } from "../../interfaces";
// import "../../styles/components/templates/DNDTemplate.scss";
// import { useStateTemplate } from "../../hooks/index";
// import { DND } from "../../config";
import { Certifications, Social } from "../../types";
import { cleanUrl } from "../../utils";
import { baseUrl } from "../../config";

const {
  website,
  address,
  addressLink,
  socials,
  phone,
  badgesTop,
  badgesBottom,
  logo,
} = {
  website: "https://www.d7d.com.au/",
  address: "U 1, 153-155 Rooks Rd, Vermont VIC 3133",
  addressLink:
    "https://www.google.com.au/maps/place/Unit+1%2F153-155+Rooks+Rd,+Vermont+VIC+3133/@-37.8326213,145.1850969,17z/data=!3m1!4b1!4m6!3m5!1s0x6ad63ed3539987fb:0x9a62a927992f20b!8m2!3d-37.8326213!4d145.1876718!16s%2Fg%2F11q8vv6n5t",
  socials: null,
  phone: ["1300 933 933 ", " +61 3 9000 8933"],
  badgesTop: null,
  badgesBottom: null,
  logo: `${baseUrl}/companyLogos/dnd.png`,
};

const DNDTemplate = ({ name, job, mobile, email }: ITemplate) => {
  // const { website, address, phone, socials, badgesTop, badgesBottom, logo } =
  //   useStateTemplate(DND);

  return (
    <table>
      <thead>
        <tr>
          <td>
            <img className="DND-logo" src={logo} alt="DND logo" width="300" />
          </td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td
            className="DND-user"
            style={{
              padding: "24px 0 20px",
              borderBottom: "0.5px solid #273568",
            }}
          >
            <div
              className="DND-name"
              style={{
                color: "#273568",
                fontWeight: 600,
                fontSize: "18px",
                textTransform: "uppercase",
                paddingBottom: "4px",
              }}
            >
              {name}
            </div>
            <div
              className="DND-job"
              style={{
                color: "#6eb2a7",
                fontWeight: 600,
                fontSize: "16px",
                textTransform: "capitalize",
              }}
            >
              {job}
            </div>
          </td>
        </tr>
        {mobile.length > 0 && (
          <tr>
            <td className="DND-info mobile" style={{ padding: "24px 0 0 0 " }}>
              <span
                className="bi-key"
                style={{
                  color: "#273568",
                  fontWeight: 700,
                  fontSize: "12px",
                  textTransform: "uppercase",
                  width: "60px",
                }}
              >
                Mobile
              </span>
              <span
                className="bi-divider"
                style={{
                  color: "#273568",
                  fontWeight: 300,
                  fontSize: "13px",
                  padding: "0 5px",
                }}
              >
                |
              </span>
              <a
                className="bi-value"
                style={{
                  color: "#4e4d50",
                  fontWeight: 400,
                  fontSize: "12px",
                  textDecoration: "none",
                }}
                href={`tel:[${mobile.replace(/\s/g, "")}]`}
              >
                {mobile}
              </a>
            </td>
          </tr>
        )}

        <tr>
          <td
            className={`DND-info ${mobile.length === 0 ? "mobile" : ""}`}
            style={{ padding: mobile.length === 0 ? "24px 0 0 0" : "" }}
          >
            <span
              className="bi-key"
              style={{
                color: "#273568",
                fontWeight: 700,
                fontSize: "12px",
                textTransform: "uppercase",
                width: "60px",
              }}
            >
              Phone
            </span>
            <span
              className="bi-divider"
              style={{
                color: "#273568",
                fontWeight: 300,
                fontSize: "13px",
                padding: "0 5px",
              }}
            >
              |
            </span>
            {phone.map((data, index) => (
              <>
                <a
                  className="bi-value"
                  style={{
                    color: "#4e4d50",
                    fontWeight: 400,
                    fontSize: "12px",
                    textDecoration: "none",
                  }}
                  href={`tel:[${data.replace(/\s/g, "")}]`}
                >
                  {data}
                </a>
                {index % 2 === 0 && (
                  <span
                    className="bi-key"
                    style={{
                      color: "#273568",
                      fontWeight: 700,
                      fontSize: "12px",
                      textTransform: "uppercase",
                      width: "60px",
                    }}
                  >
                    or
                  </span>
                )}
              </>
            ))}
          </td>
        </tr>
        <tr>
          <td className="DND-info">
            <span
              className="bi-key"
              style={{
                color: "#273568",
                fontWeight: 700,
                fontSize: "12px",
                textTransform: "uppercase",
                width: "60px",
              }}
            >
              Email
            </span>
            <span
              className="bi-divider"
              style={{
                color: "#273568",
                fontWeight: 300,
                fontSize: "13px",
                padding: "0 5px",
              }}
            >
              |
            </span>
            <a
              className="bi-value"
              style={{
                color: "#4e4d50",
                fontWeight: 400,
                fontSize: "12px",
                textDecoration: "none",
              }}
              href={`mailto:${email}`}
              target="_blank"
              rel="noreferrer"
            >
              {email}
            </a>
          </td>
        </tr>
        <tr>
          <td className="DND-info">
            <span
              className="bi-key"
              style={{
                color: "#273568",
                fontWeight: 700,
                fontSize: "12px",
                textTransform: "uppercase",
                width: "60px",
              }}
            >
              Website
            </span>
            <span
              className="bi-divider"
              style={{
                color: "#273568",
                fontWeight: 300,
                fontSize: "13px",
                padding: "0 5px",
              }}
            >
              |
            </span>
            <a
              className="bi-value"
              style={{
                color: "#4e4d50",
                fontWeight: 400,
                fontSize: "12px",
                textDecoration: "none",
              }}
              href={website}
              target="_blank"
              rel="noreferrer"
            >
              {cleanUrl(website)}
            </a>
          </td>
        </tr>
        <tr>
          <td className="DND-info">
            <span
              className="bi-key"
              style={{
                color: "#273568",
                fontWeight: 700,
                fontSize: "12px",
                textTransform: "uppercase",
                width: "60px",
              }}
            >
              Address
            </span>
            <span
              className="bi-divider"
              style={{
                color: "#273568",
                fontWeight: 300,
                fontSize: "13px",
                padding: "0 5px",
              }}
            >
              |
            </span>
            <a
              className="bi-value"
              style={{
                color: "#4e4d50",
                fontWeight: 400,
                fontSize: "12px",
                textDecoration: "none",
              }}
              href={addressLink}
              target="_blank"
              rel="noreferrer"
            >
              {address}
            </a>
          </td>
        </tr>

        <tr>
          <table className="DND-sm" style={{ padding: "25px 0" }}>
            {(socials || []).map(({ url, linkLogo }: Social) => {
              return (
                <td
                  className="DND-sm-link-container"
                  style={{ margin: "0.1px", paddingRight: "20.5px" }}
                  key={linkLogo[0].url}
                >
                  <a
                    className="DND-sm-link"
                    href={url}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      className="DND-sm-link-img"
                      src={linkLogo[0].url}
                      alt="social media"
                      height="29"
                    />
                  </a>
                </td>
              );
            })}
          </table>
        </tr>
        <tr>
          <table className="DND-badges">
            {(badgesTop || []).map((obj: Certifications) => {
              return (
                <td
                  className="DND-badges-link-img-container"
                  style={{ margin: "0.1px", paddingRight: "0px" }}
                  key={obj.url}
                >
                  <img
                    className="DND-badges-link-img"
                    src={obj.url}
                    alt="badges"
                    height="60"
                  />
                </td>
              );
            })}
          </table>
        </tr>
        <tr>
          <table className="DND-badges">
            {(badgesBottom || []).map((obj: Certifications) => {
              return (
                <td
                  className="DND-badges-link-img-container"
                  style={{ margin: "0.1px", paddingRight: "0px" }}
                  key={obj.url}
                >
                  <img
                    className="DND-badges-link-img"
                    src={obj.url}
                    alt="badges"
                    height="60"
                  />
                </td>
              );
            })}
          </table>
        </tr>
      </tbody>
    </table>
  );
};

export default DNDTemplate;
