import React from "react";
import "../styles/components/Input.scss";

interface IInput {
  type: string;
  name: string;
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
}

const Input = ({ type, name, value, setValue }: IInput) => {
  const formatPhoneNumber = (inputValue: string): string => {
    const cleanedPhoneNumber = `${inputValue}`.replace(/\D/g, "");
    let match: RegExpMatchArray | null;
    // Check if the number is of the form +61 04xx xxx xxx
    if (cleanedPhoneNumber.startsWith("610")) {
      match = cleanedPhoneNumber.match(/^(\d{2})(\d{0,4})(\d{0,3})(\d{0,3})$/);
    } else {
      match = cleanedPhoneNumber.match(/^(\d{2})(\d{0,3})(\d{0,3})(\d{0,3})$/);
    }
    // Based on match groups, format the number.
    if (match) {
      const intlCode = match[1].length > 0 ? `+${match[1]} ` : "";
      return [
        intlCode,
        match[2].length > 0 ? `${match[2]}` : "",
        match[3].length > 0 ? ` ${match[3]}` : "",
        match[4].length > 0 ? ` ${match[4]}` : "",
      ].join("");
    }
    return inputValue;
  };
  return (
    <div className="input">
      <label className="input-label" htmlFor={name}>
        {name}
        {name === "mobile" && <small> (Optional)</small>}
      </label>

      <input
        className="input-field"
        value={value}
        name={name}
        type={type}
        onChange={(e) => {
          if (name !== "mobile") {
            setValue(e.target.value);
          } else {
            const inputValue = e.target.value;
            if (inputValue === "+61") {
              setValue("");
            } else if (!inputValue.startsWith("+61") && value === "") {
              setValue(`+61${inputValue}`);
            } else {
              setValue(formatPhoneNumber(inputValue));
            }
          }
        }}
        placeholder={name !== "mobile" ? "" : "+61"}
      />
    </div>
  );
};

export default Input;
