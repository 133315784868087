import React, { KeyboardEventHandler } from "react";
import "../styles/components/Navigation.scss";
import useStore from "../stores/store";

const Navigation = () => {
  const { currentPageIndex, setCurrentPageIndex } = useStore();

  const clickHandler = (index: number) => {
    return (event: React.MouseEvent) => {
      setCurrentPageIndex(index % 2);
      event.preventDefault();
    };
  };

  return (
    <nav className="navigation">
      <button className="nav-inner" type="button" onClick={clickHandler(1)}>
        ROR SIGNATURES
      </button>
      <button className="nav-avatar" type="button" onClick={clickHandler(0)}>
        AVATAR GENERATOR
      </button>
    </nav>
  );
};

export default Navigation;
